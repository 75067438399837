
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { FormBase, FormInput, FormError, FormSubmit, FormSelect, FormFile, FormPhone } from "@/components/forms";
import { TeamFunctions } from "@/store/modules/activity-account.store";
import type { Office } from "@/store/modules/office.store";

@Component({
    components: {
        FormBase,
        FormInput,
        FormError,
        FormFile,
        FormPhone,
        FormSubmit,
        FormSelect,
        Spinner: () => import("@/components/general/spinner.vue"),
    },
})
export default class PageAdminUsersShow extends Vue {
    @Getter("account/viewing") viewingAccount!: Account;
    @Getter("office/all") offices!: Office[];

    @Action("account/clearViewing") clearViewing!: () => void;
    @Action("account/patch") updateAccount!: AccountUpdate;
    @Action("auth/passwordForgot") passwordForgot!: (payload: PasswordForgotPayload) => Promise<{}>;

    @Ref() form!: FormClass;
    @Ref() passwordForm!: FormClass;

    payload: any = null;
    passwordChangeLoading: boolean = false;
    passwordChangeRequested: boolean = false;
    updatesLoading: boolean = false;

    signature: Media | null = null;
    profileImage: Media | null = null;

    uploadingFile: string | false = false;

    created() {
        this.payload = {
            id: this.viewingAccount.id,
            email: this.viewingAccount.email,
            first_name: this.viewingAccount.first_name,
            last_name: this.viewingAccount.last_name,
            phone: this.viewingAccount.phone,
            function_title: this.viewingAccount.function_title,
            whise_id: this.viewingAccount.whise_id,
            locale: this.viewingAccount.locale,
            sign_function: this.viewingAccount.sign_function,
            biv_number: this.viewingAccount.biv_number,
            signature: [],
            office_ids: [],
        };
    }

    mounted() {
        if (this.viewingAccount && this.viewingAccount.signature) {
            this.signature = this.viewingAccount.signature;
        }

        if (this.viewingAccount && this.viewingAccount.media) {
            this.profileImage = this.viewingAccount.media;
        }

        if (this.viewingAccount && this.viewingAccount.offices) {
            this.payload.office_ids = this.viewingAccount.offices.map((o) => o.id);
        }
    }

    get functionTitles() {
        return Object.values(TeamFunctions).map((title) => ({ value: title, label: this.$t(`views.account.functions.${title}`) as string }));
    }

    get canSetLegalOptions() {
        return ![TeamFunctions.backoffice, TeamFunctions.marketeer, TeamFunctions.financeControl, TeamFunctions.financeExecution, TeamFunctions.legal].includes(this.payload.function_title);
    }

    get officeOptions() {
        return this.offices.map((o) => {
            return {
                label: o.name,
                value: o.id,
            };
        });
    }

    @Watch("signature", { deep: true })
    handleSignatureChanged() {
        if (this.payload && this.signature) {
            this.payload.signature = [
                {
                    uuid: this.signature.uuid,
                    name: this.signature.name,
                },
            ];
        }
    }

    @Watch("profileImage", { deep: true })
    handleProfileImageChanged() {
        if (this.payload && this.profileImage) {
            this.payload.media = [
                {
                    uuid: this.profileImage.uuid,
                    name: this.profileImage.name,
                },
            ];
        }
    }

    @Watch("media", { deep: true })
    handleMediaChanged() {
        this.uploadingFile = false;
    }

    handleFileUploaded(media: Media | null, name: "signature" | "profile-image") {
        this.uploadingFile = name;

        if (media && name === "signature") {
            this.signature = media;
        }

        if (media && name === "profile-image") {
            this.profileImage = media;
        }
    }

    submit() {
        this.updatesLoading = true;
        // @ts-ignore
        this.updateAccount(this.payload).then(() => {
            this.updatesLoading = false;
            this.$toast.open({ message: this.$t("views.settings.admin-users.update_success") as string, type: "success", position: "bottom-right" });
        });
    }

    private submitPasswordChange(form: FormClass) {
        this.passwordForgot({ email: this.viewingAccount.email })
            .then(() => {
                this.passwordChangeRequested = true;
            })
            .catch((errorResponse: ErrorResponse) => (this.$errorResponse = errorResponse))
            .finally(() => form.reset());
    }
}
